import React from "react"
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/layout"
import CoverImage from "../components/cover_image";

const IndexPage = (props) => {
  const data = useStaticQuery(query)
  return (
  <>
  <Layout seo={data.strapiHome.seo}>
    {data.strapiHome.featured.map((featuredStory) => (
      <CoverImage story={featuredStory.story} />
    ))}
  </Layout>
  </>
  )
}

export default IndexPage

export const query = graphql`{
  strapiHome {
    seo {
      title
      description
      shareImage {
        alternativeText
        file {
          publicURL
          childImageSharp {
            gatsbyImageData(width: 660)
          }
        }
      }
    }
    featured {
      story {
        strapiId
        slug
        title
        cover {
          image {
            alternativeText
            caption
            file {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH,
                  placeholder: DOMINANT_COLOR,
                )
              }
            }
          }
        }
      }
    }
  }
}
`
