import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import { description, excerpt, portrait } from "./cover_image.module.css"

const CoverImage = ({ story }) => (
  <Link to={`/story/${story.slug}`}>
    <div className={portrait}>
      <GatsbyImage
        image={story.cover.image.file.childImageSharp.gatsbyImageData}
        alt={story.cover.image.alternativeText} />
      <div className={description}>
        <p className={excerpt}>{story.cover.image.caption}</p>
      </div>
    </div>
  </Link>
)

export default CoverImage;